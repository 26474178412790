import { isNodeList, insert } from './../shared/utils';

// debounce
const debounce = function (func) {
  let timer;
  return function (event) {
    if (timer) clearTimeout(timer);
    timer = setTimeout(func, 200, event);
  };
};

// add class
const addClass = (target, className) => {
  if (isNodeList(target)) {
    target.forEach(el => {
      el.classList.add(className);
    });
    return;
  }

  target.classList.add(className);
};

// remove class
const removeClass = (target, className) => {
  if (isNodeList(target)) {
    target.forEach(el => {
      el.classList.remove(className);
    });
    return;
  }

  target.classList.remove(className);
};

// 拖拉類型控制
class dragControl {
  constructor(el) {
    this.$element = el;
    this.option = el.s.option.drag;
    this.#init();
  }

  // 初始化
  #init() {
    const $this = this;
    if (!$this.$element) return;

    $this.$container = $this.$element.querySelector('.drag-container');
    $this.$wrapper = $this.$container.querySelector('.wrapper');
    console.log($this.$element,$this.$container,$this.$wrapper);

    // scroll & resize event
    const updateEvent = () => {
      $this.#scrollerDetect();
      $this.#buttonDetect();
    };

    // scroll event handle
    $this.$wrapper.removeEventListener('scroll', updateEvent);
    $this.$wrapper.addEventListener('scroll', updateEvent);

    // resize event handle
    window.removeEventListener('resize', debounce(updateEvent));
    window.addEventListener('resize', debounce(updateEvent));

    // 左右拖拉事件綁定，若為 collapse 則強制啟用 draggable
    if ($this.option.draggable || $this.$element.s.type == 'collapse') $this.#bindDragEvent();
    // 左右箭頭事件綁定，若為 collapse 則不輸出 .navigation
    if ($this.option.navigation && $this.$element.s.type !== 'collapse') {
      $this.$container.insertAdjacentHTML(
        insert.prepend,
        `<div class="navigation">
        <div class="button prev">
          <div></div>
        </div>
        <div class="button next">
          <div></div>
        </div>
      </div>`,
      );

      $this.$button = $this.$container?.querySelectorAll('.button');
      $this.#bindButtonEvent();
    }
    // 卷軸位置判斷
    $this.#scrollerDetect();
    // 隱藏按鈕判斷
    $this.#buttonDetect();
    // 選項事件綁定
    $this.#bindItemEvent();
    // 更新 active 位置
    // $this.update();
  }
  // 更新 active 位置
  update(element) {
    const $wrapper = element.querySelector('.wrapper');
    const $active = $wrapper?.querySelector('.active');
    if ($active) {
      // 移動至 active class 處
      const moveDistance = $active.offsetLeft + $active.getBoundingClientRect().width / 2 - $wrapper.getBoundingClientRect().width / 2;
      $wrapper.scrollTo({
        left: moveDistance,
        behavior: 'smooth',
      });
    }
  }
  // 左右箭頭事件綁定
  #bindButtonEvent() {
    const $this = this;
    const { $wrapper, $button } = $this;
    // button event
    const buttonEvent = function () {
      const type = this.classList.contains('next');
      const fix = parseInt($wrapper.getBoundingClientRect().width * 0.7);

      $wrapper.scrollTo({
        left: type ? $wrapper.scrollLeft + fix : $wrapper.scrollLeft - fix,
        behavior: 'smooth',
      });

      setTimeout(() => {
        $this.#buttonDetect();
      }, 100);
    };

    // button event handle
    $button.forEach($el => {
      $el.removeEventListener('click', buttonEvent);
      $el.addEventListener('click', buttonEvent);
    });
  }
  // 左右拖拉事件綁定
  #bindDragEvent() {
    const { $wrapper } = this;

    // 是否為點擊狀態
    let isDown = false;
    // 是否為移動狀態
    let isMoved = false;
    // 起始 X 軸數值
    let startX = 0;
    // 卷軸數值
    let scrollLeft = 0;

    // mousedown event
    const mousedownEvent = function (event) {
      event.preventDefault();

      isMoved = false;
      isDown = true;

      startX = event.pageX - $wrapper.offsetLeft;
      scrollLeft = $wrapper.scrollLeft;
    };

    // mousedown event handle
    $wrapper.removeEventListener('mousedown', mousedownEvent);
    $wrapper.addEventListener('mousedown', mousedownEvent);

    // mouseleave event
    const mouseleaveEvent = function () {
      isMoved = false;
      isDown = false;
    };

    // mouseleave event handle
    $wrapper.removeEventListener('mouseleave', mouseleaveEvent);
    $wrapper.addEventListener('mouseleave', mouseleaveEvent);

    // mouseup event
    const mouseupEvent = function (event) {
      event.preventDefault();
      isDown = false;
    };

    // mouseup event handle
    $wrapper.removeEventListener('mouseup', mouseupEvent);
    $wrapper.addEventListener('mouseup', mouseupEvent);

    // mousemove event
    const mousemoveEvent = function (event) {
      event.preventDefault();

      isMoved = true;

      if (!isDown) return;

      const x = event.pageX - $wrapper.offsetLeft;
      const walk = x - startX;
      $wrapper.scrollTo({
        left: scrollLeft - walk,
      });
    };

    // mousemove event handle
    $wrapper.removeEventListener('mousemove', mousemoveEvent);
    $wrapper.addEventListener('mousemove', mousemoveEvent);

    const reset = function (event) {
      if (isMoved) {
        event.preventDefault();
        event.stopPropagation();
      }
    };

    $wrapper.querySelectorAll('a').forEach($el => {
      $el.removeEventListener('click', reset);
      $el.addEventListener('click', reset);
    });
  }
  // 卷軸位置判斷
  #scrollerDetect() {
    const { $container, $wrapper } = this;

    const gate = $wrapper.scrollWidth - $wrapper.clientWidth;
    const value = $wrapper.scrollLeft;

    if (gate <= 0) return;

    addClass($container, 'scrollable');

    // 最前
    if (value == 0) {
      addClass($wrapper, 'start');
      removeClass($wrapper, 'end');
    }
    // 最後
    else if (value >= gate) {
      removeClass($wrapper, 'start');
      addClass($wrapper, 'end');
    }
    // 中間
    else {
      addClass($wrapper, 'center');
      removeClass($wrapper, 'start');
      removeClass($wrapper, 'end');
    }
  }
  // 隱藏按鈕判斷
  #buttonDetect() {
    const { $wrapper, $button, $element } = this;

    if (!$button) return;

    const gate = $wrapper.scrollWidth - $wrapper.clientWidth;
    const value = $wrapper.scrollLeft;

    if (gate <= 0) {
      addClass($button, 'hide');
      return;
    }

    // 最前
    if (value == 0) {
      $button.forEach($el => {
        if ($el.classList.contains('next')) {
          removeClass($el, 'hide');
          return;
        }

        addClass($el, 'hide');
      });
    }
    // 最後
    else if (value >= gate) {
      $button.forEach($el => {
        if ($el.classList.contains('next')) {
          addClass($el, 'hide');
          return;
        }

        removeClass($el, 'hide');
      });
    }
    // 中間
    else {
      removeClass($button, 'hide');
    }
  }
  // 選項事件綁定
  #bindItemEvent() {
    const { $element } = this;

    const itemEvent = $el => {
      if (this.option.selected) {
        const selectOption = $el.getAttribute('data-option').trim();
        $element.setAttribute('m4-status', selectOption);
      }
    };

    function clickHandler() {
      itemEvent(this);
    }

    this.$container.querySelectorAll('.item').forEach(el => {
      el.removeEventListener('click', clickHandler);
      el.addEventListener('click', clickHandler);
    });
  }
}

// 收合類型控制
class collapseControl {
  constructor(el) {
    this.$element = el;
    this.option = el.s.option.collapse;
    this.#init();
  }

  // 初始化
  #init() {
    if (!this.$element) return;

    this.$container = this.$element.querySelector('.collapse-container');
    this.$wrapper = this.$container.querySelector('.wrapper');

    // 隱藏按鈕判斷
    if (this.#buttonDetect()) {
      // 展開箭頭事件綁定
      this.#bindButtonEvent();
    }

    // 選項事件綁定
    this.#bindItemEvent();
  }
  // 隱藏按鈕判斷
  #buttonDetect() {
    const $drag = this.$element.querySelector('.drag-container');
    const $wrapper = $drag.querySelector('.wrapper');

    if ($wrapper.scrollWidth - $wrapper.clientWidth > 0) {
      $drag.insertAdjacentHTML(insert.append, `<div class="open-collapse"></div>`);
      this.$button = $drag.querySelector('.open-collapse');
      return true;
    }

    return false;
  }
  // 展開箭頭事件綁定
  #bindButtonEvent() {
    const { $element, $button } = this;

    // button event
    const buttonEvent = function () {
      if ($element.classList.contains('expand')) {
        $element.classList.remove('expand');
      } else {
        $element.classList.add('expand');
      }
    };

    // button event handle
    $button.removeEventListener('click', buttonEvent);
    $button.addEventListener('click', buttonEvent);
  }
  // 選項事件綁定
  #bindItemEvent() {
    const { $element } = this;

    const itemEvent = ($el, collapse) => {
      if (collapse) {
        if ($element?.classList.contains('expand')) {
          $element?.classList.remove('expand');
        } else {
          $element?.classList.add('expand');
        }
      }

      if (this.option.selected) {
        const selectOption = $el.getAttribute('data-option').trim();
        $element.setAttribute('m4-status', selectOption);
      }
    };

    function clickHandler() {
      itemEvent(this, true);
    }

    this.$container.querySelectorAll('.item').forEach(el => {
      el.removeEventListener('click', clickHandler);
      el.addEventListener('click', clickHandler);
    });
  }
}

// create template method
const createTemplate = m4 => {
  const { type, option, originalDomString } = m4.s;

  const $storage = document.createElement('div');
  if (type == 'drag') {
    $storage.innerHTML = fesdDB.multipurpose4.TEMPLATE[type]().trim();

    const $wrapper = $storage.querySelector('.drag-container .wrapper');
    $wrapper.insertAdjacentHTML(insert.append, originalDomString);
  }
  if (type == 'collapse') {
    $storage.innerHTML = fesdDB.multipurpose4.TEMPLATE[type](option?.collapse).trim();
    // drag
    const $container = $storage.querySelector('.drag-container .wrapper');
    $container.insertAdjacentHTML(insert.append, originalDomString);
    // collapse
    const $model = $storage.querySelector('.collapse-container .wrapper');
    $model.insertAdjacentHTML(insert.append, originalDomString);
  }
  if (type == 'dropdown') {
    $storage.innerHTML = fesdDB.multipurpose4.TEMPLATE[type](option?.dropdown).trim();

    const $dropdown = $storage.querySelector('dropdown-el');
    $dropdown.insertAdjacentHTML(insert.append, originalDomString);
  }

  return $storage.children;
};

class Multipurpose4 extends HTMLElement {
  constructor() {
    super();
    this.initialize = false;
    this.__events__ = {};
    this.s = {};
    this.s.originalDomString = this.innerHTML.trim().replace(/\n/g, '');
    this.previousWidth = window.innerWidth;
  }
  static get observedAttributes() {
    return ['m4-type', 'm4-status'];
  }
  attributeChangedCallback(attr, oldVal, newVal) {
    switch (attr) {
      case 'm4-type':
        if (oldVal === null || oldVal === newVal) return;
        this.s.type = newVal;
        this.#distribute();
        break;
      case 'm4-status':
        if (oldVal === newVal) return;
        const type = this.getAttribute('m4-type');
        if (type !== 'drag' && type !== 'collapse') return;
        // 左右拖拉
        this.querySelectorAll('.drag-container .item').forEach(el => {
          el.classList.remove('active');
        });
        this.querySelector(`.drag-container .item[data-option="${newVal}"]`)?.classList.add('active');
        // 展開選項
        if (type === 'collapse') {
          this.querySelectorAll('.collapse-container .item').forEach(el => {
            el.classList.remove('active');
          });
          this.querySelector(`.collapse-container .item[data-option="${newVal}"]`)?.classList.add('active');
        }
        this.constructor.drag?.update(this);
        break;
    }
  }
  connectedCallback() {
    if (this.initialize || this.classList.contains('m4-init')) return;
    this.initialize = true;
    this.#init();
  }
  update() {
    // 斷點設定
    this.#breakpoint();
  }
  #init() {
    let customize = {};

    if (!this.hasAttribute('m4-value')) {
      this.setAttribute('m4-value', '');
    }

    this.s.type = this.getAttribute('m4-type') ?? fesdDB.multipurpose4.SETTINGS.type;
    if (this.hasAttribute('m4-option')) {
      customize = this.getAttribute('m4-option') ? JSON.parse(this.getAttribute('m4-option')) : {};
      this.removeAttribute('m4-option');
    }

    const updateEvent = event => {
      if (event.type === 'resize') {
        if (window.innerWidth === this.previousWidth) return;
        this.previousWidth = window.innerWidth;
      }
      this.update();
    };

    // resize event handle
    window.removeEventListener('resize', debounce(updateEvent));
    window.addEventListener('resize', debounce(updateEvent));

    this.s.option = {};
    this.s.option.drag = Object.assign({}, fesdDB.multipurpose4.SETTINGS.drag, customize?.drag);
    this.s.option.collapse = Object.assign({}, fesdDB.multipurpose4.SETTINGS.collapse, customize?.collapse);
    this.s.option.dropdown = Object.assign({}, fesdDB.multipurpose4.SETTINGS.dropdown, customize?.dropdown);
    this.s.option.breakpoint = Object.assign({}, fesdDB.multipurpose4.SETTINGS.breakpoint, customize?.breakpoint);

    this.#render();
  }
  #render() {
    this.classList.add('m4-init');
    // 斷點設定
    this.#breakpoint();
  }
  // 斷點設定
  #breakpoint() {
    const obj = Object.keys(this.s.option.breakpoint);
    if (!obj.length) {
      this.#distribute();
      return;
    }
    obj
      .map(keys => Number(keys))
      .sort((a, b) => b - a)
      .some(point => {
        if (window.innerWidth >= point) {
          this.s.type = this.s.option.breakpoint[point]?.type;
          this.setAttribute('m4-type', this.s.type);
          this.s.option.drag = Object.assign({}, this.s.option.drag, this.s.option.breakpoint[point]?.drag);
          this.s.option.collapse = Object.assign({}, this.s.option.collapse, this.s.option.breakpoint[point]?.collapse);
          this.s.option.dropdown = Object.assign({}, this.s.option.dropdown, this.s.option.breakpoint[point]?.dropdown);
        }
        this.#distribute();
        return window.innerWidth >= point;
      });
  }
  // check type
  #distribute() {
    const { type } = this.s;

    this.innerHTML = '';
    [...createTemplate(this)].forEach(el => {
      this.append(el);
    });

    // 新增預設 active class
    const dragContainer = this.querySelector(`.drag-container .item[data-option="${this.getAttribute('m4-status')}"]`);
    const collapseContainer = this.querySelector(`.collapse-container .item[data-option="${this.getAttribute('m4-status')}"]`);
    dragContainer?.classList.add('active');
    collapseContainer?.classList.add('active');

    switch (type) {
      case 'drag':
        this.constructor.drag = new dragControl(this);
        break;
      case 'collapse':
        this.constructor.drag = new dragControl(this);
        this.constructor.collapse = new collapseControl(this);
        break;
      case 'dropdown':
        // this.#dropdown();
        break;
    }
    this.constructor.drag?.update(this);
  }
}

if (!customElements.get('multipurpose-nav')) {
  customElements.define('multipurpose-nav', Multipurpose4);
}

export default Multipurpose4;
