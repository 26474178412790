import SHARED from '../shared/shared';
import { isString, isElement, isNodeList, isElementExist, isFunction, getElement, getAllElements, createUid, toHTMLElement, jsonParse, warn, error } from '../shared/utils';

('use strict');

const collapseHandle = $wrapper => {
  const { defaultOptions } = $wrapper.collapse;
  const { collapseClass, target, transition } = defaultOptions;

  const $target = $wrapper.querySelector(target);

  $wrapper.classList.add(collapseClass);

  $target.style.height = 0;
  $target.style['transition-property'] = transition.property;
  $target.style['transition-duration'] = transition.duration;
  $target.style['transition-timing-function'] = transition.function;
  $target.style['transition-delay'] = transition.delay;
  $target.style.overflow = 'hidden';
};

const expandHandle = $wrapper => {
  const { defaultOptions } = $wrapper.collapse;
  const { collapseClass, target, transition } = defaultOptions;

  const $target = $wrapper.querySelector(target);
  const height = $target.children[0].offsetHeight;
  $wrapper.collapse.height = height;

  // console.log( height );
  // console.log( $target.children[0].offsetHeight );
  $wrapper.classList.remove(collapseClass);

  $target.style.display = 'block';
  $target.style.height = `${height}px`;
  $target.style['transition-property'] = transition.property;
  $target.style['transition-duration'] = transition.duration;
  $target.style['transition-timing-function'] = transition.function;
  $target.style['transition-delay'] = transition.delay;
  $target.style.overflow = 'hidden';
};

class Collapse4 {
  constructor(el, options = {}) {
    // 可傳 string 或 element 或 nodeList
    if (!isString(el) && !isElement(el) && !isNodeList(el) && !isElementExist(el)) return;

    this.__storage__ = {
      el,
      options,
    };

    this.#create();
  }

  #create() {
    const { el, options } = this.__storage__;
    const { SETTINGS, EVENTS } = fesdDB.collapse4;

    this.elements = getAllElements(el);
    this.options = Object.assign({}, SETTINGS, options);
    this.__events__ = Object.assign({}, EVENTS);

    if (this.options.on) {
      for (const [k, v] of Object.entries(this.options.on)) {
        this.__events__[k] = [v];
      }
    }

    this.#init();
  }

  #init() {
    const { elements, options } = this;

    elements.forEach(el => {
      el.collapse = {};
      el.collapse.instance = this;
      el.collapse.defaultOptions = options;

      this.#trigger(el);
    });

    this.emit('init');
  }

  #trigger(el) {
    const { options } = this;
    const { collapseClass, block, target, defaultOpen, targetStopPropagation, defaultActiveMark } = options;

    // collapse-wrapper
    // collapse-block
    // collapse-target

    const $wrapper = el;
    const $target = $wrapper.querySelector(target);
    $wrapper.collapse.height = $target.children[0].offsetHeight;

    // const $block = $wrapper.querySelector(block);

    // 是否預設全打開
    if (defaultOpen) {
      $target.style.height = `${$target.children[0].offsetHeight}px`;
      $wrapper.classList.remove(collapseClass);
    } else {
      if (!$wrapper.classList.contains(defaultActiveMark) && !$wrapper.hasAttribute(defaultActiveMark)) {
        $wrapper.classList.add(collapseClass);
        $target.style.height = '0px';
        $target.style.overflow = 'hidden';
      } else {
        $target.style.height = `${$target.children[0].offsetHeight}px`;
        $wrapper.classList.remove(collapseClass);
      }
    }

    // update() 避免重複綁定
    $wrapper.removeEventListener('click', this.#event);
    $wrapper.addEventListener('click', this.#event);

    // target 點擊不收合
    if (targetStopPropagation) {
      const event = e => {
        e.stopPropagation();
      };
      // update() 避免重複綁定
      $target.removeEventListener('click', event);
      $target.addEventListener('click', event);
    }
  }

  #event() {
    const { instance, defaultOptions } = this.collapse;
    const { collapseClass, block, target, single } = defaultOptions;

    const $wrapper = this;

    if ($wrapper.classList.contains(collapseClass)) {
      if (single) {
        $wrapper.parentNode.querySelectorAll(`.${$wrapper.classList[0]}`).forEach(el => {
          collapseHandle(el);
        });
      }

      expandHandle($wrapper);
    } else {
      collapseHandle($wrapper);
    }

    instance.emit('afterCollapse');
  }

  update() {
    this.#create();
  }
}

Object.assign(Collapse4.prototype, SHARED);

export default Collapse4;
