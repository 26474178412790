import { insert, isString, isElementExist, getAllElements } from './../shared/utils';

('use strict');

const getTargetUrl = type => {
  // facebook
  if (type == 'facebook') {
    return 'https://www.facebook.com/sharer/sharer.php?u=';
  }

  // line
  if (type == 'line') {
    // desktop & mobile 網址不同
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      return 'http://line.naver.jp/R/msg/text/?';
    }
    return 'https://lineit.line.me/share/ui?url=';
  }

  // twitter
  if (type == 'twitter') {
    return 'https://twitter.com/intent/tweet?url=';
  }

  // linkedin
  if (type == 'linkedin') {
    return `http://www.linkedin.com/shareArticle?mini=true&title=${document.title}&source=${document.title}&url=`;
    // return `https://www.linkedin.com/sharing/share-offsite/?url=`;
  }

  // telegram
  if (type == 'telegram') {
    return 'https://telegram.me/share/url?url=';
  }
};

class Share4 {
  constructor(el, options = {}) {
    this.__storage__ = {
      el,
      options,
    };

    this.#create();
  }

  #create() {
    const { el, options } = this.__storage__;
    // 從 fesdDB 提取設定
    const { SETTINGS } = fesdDB.share4;
    if (!isString(el) || !isElementExist(el)) return;

    this.elements = getAllElements(el);

    this.options = Object.assign({}, SETTINGS, options);

    this.#init();
  }

  #init() {
    const { elements, options } = this;

    elements.forEach(targets => {
      targets.querySelectorAll('[share-target]').forEach(el => {
        el.share = {};
        el.share.instance = this;
        el.share.params = options;
        el.share.eventHandler = this.#trigger;
        el.addEventListener('click', el.share.eventHandler);
      });
    });
  }

  #trigger() {
    /** the keyword `this` in this method is pointed to the click target */
    const { eventHandler, params } = this.share;
    const type = this.getAttribute('share-target');

    const utm = {
      source: this.getAttribute('utm-source'),
      medium: this.getAttribute('utm-medium'),
      campaign: this.getAttribute('utm-campaign'),
    };

    const copy = {
      success: this.getAttribute('copy-success') || params.success,
      text: this.getAttribute('copy-text') || params.text,
      className: this.getAttribute('copy-class') || params.className,
      duration: this.getAttribute('copy-duration') || params.duration,
    };

    const baseUrl = encodeURIComponent(document.URL);
    let shareUrl = baseUrl;

    // 若為 wechat 則另開視窗掃描 QRcode
    if (type == 'wechat') {
      // https://api.qrserver.com/v1/create-qr-code/?data=${url}&size=300x300
      window.open(`https://api.qrserver.com/v1/create-qr-code/?data=${baseUrl}&size=250x250`, 'share to wechat', 'width=300,height=300');
      return;
    }

    // 若為 url 則複製連結
    if (type == 'url') {
      if (isElementExist('.copied-wrapper')) return;

      document.querySelector('body').insertAdjacentHTML(insert.append, `<div class='copied-wrapper'><div class='text'>${copy.success ? copy.success : ''}</div><input id='clipboard' type='text' readonly></div>`);

      const $copied = document.querySelector('.copied-wrapper');

      if (copy.className) {
        $copied.classList.add(copy.className);
      }
      $copied.style.top = this.getBoundingClientRect().top + window.scrollY + 'px';
      $copied.style.left = this.getBoundingClientRect().left + this.getBoundingClientRect().width / 2 + 'px';
      $copied.style.display = 'block';

      const $clipboard = document.querySelector('#clipboard');

      $clipboard.value = copy.text ?? window.location.href;
      $clipboard.setSelectionRange(0, 9999);
      $clipboard.select();

      if (document.execCommand('copy')) {
        // 複製對應內容
        document.execCommand('copy');
        // 動畫效果
        // **暫時使用 JQ
        $('.copied-wrapper .text')
          .fadeIn(300)
          .promise()
          .done(function () {
            setTimeout(function () {
              $('.copied-wrapper .text')
                .fadeOut(300)
                .promise()
                .done(function () {
                  $('.copied-wrapper').remove();
                });
            }, copy.duration);
          });
      }

      return;
    }

    // 其餘則使用對應網址另開新分頁
    // url + '?utm_source=Facebook' + '&utm_medium=social' + '&utm_campaign=' + campaign
    shareUrl = `${shareUrl}${utm.source ? `?utm_source=${utm.source}` : ''}${utm.medium ? `&utm_medium${utm.medium}` : ''}${utm.campaign ? `&utm_campaign${utm.campaign}` : ''}`;
    shareUrl = shareUrl.replace('?', '%3F').replace(new RegExp('&', 'g'), '%26');

    if (type == 'line') {
      // line 需帶入原始網址
      window.open(`${getTargetUrl(type)}${baseUrl}`);
    } else {
      window.open(`${getTargetUrl(type)}${shareUrl}&quote=${shareUrl}`);
    }

    this.removeEventListener('click', eventHandler);
    setTimeout(() => {
      this.addEventListener('click', eventHandler);
    }, 100);
  }
}

export default Share4;
