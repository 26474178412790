import SHARED from '../shared/shared';
import { isElementExist, getElement, getAllElements, insert, warn } from '../shared/utils.js';
// 卷軸套件
import 'overlayscrollbars/overlayscrollbars.css';
import { OverlayScrollbars } from 'overlayscrollbars';
// 縣市資料
import { cityData } from './cityData.js';

('use strict');

// create template method
const createTemplate = d4 => {
  const { TEMPLATE } = fesdDB.dropdown4;
  const { childDom } = d4.s;
  const container = document.createElement('div');
  const filter = d4.classList.contains('filter');
  const filterPlaceholder = d4.getAttribute('filter-placeholder');
  container.innerHTML = TEMPLATE(filter, filterPlaceholder);
  const content = container.querySelector('.dropdown-list');
  [...childDom].forEach(child => {
    if (child.tagName === 'LI') {
      if (child.classList.contains('has-sublayer')) {
        const subOptions = child.querySelectorAll('li');
        [...subOptions].forEach(subOption => {
          if (!subOption.hasAttribute('data-option') || subOption.getAttribute('data-option').trim() === '') {
            subOption.setAttribute('data-option', subOption.textContent.trim());
          }
        });
      } else {
        if (!child.hasAttribute('data-option') || child.getAttribute('data-option').trim() === '') {
          child.setAttribute('data-option', child.textContent.trim());
        }
      }
    }
    content.append(child);
  });

  return container.children[0];
};

/**
 * 判斷是否有設定連動
 * @param {object} d4 d4 Element
 */
const detectSync = d4 => {
  const controlElements = d4.getAttribute('control-elements')?.split(',');
  if (controlElements) {
    controlElements.forEach(el => {
      const disabledEl = document.querySelector(el);
      if (!disabledEl) {
        warn('dropdown', `Can't not find control element(${el})`);
      }
      if (disabledEl && disabledEl.classList.contains('disabled')) {
        disabledEl.classList.remove('disabled');
        if (disabledEl.tagName === 'DROPDOWN-EL' && disabledEl.s.activeLi) {
          setSelectDisplay(disabledEl, [...disabledEl.s.allLi].indexOf(disabledEl.s.activeLi));
        }
      }
    });
  }
};

/**
 * 顯示選擇的選項
 * @param {object} d4 d4 Element
 * @param {number} valueIndex 被選擇的選項索引值
 * @returns
 */
const setSelectDisplay = (d4, valueIndex) => {
  const placeholder = d4.getAttribute('d4-placeholder');
  const allLi = d4.querySelectorAll('.dropdown-list li');
  if (valueIndex < 0 || valueIndex.length === 0) {
    // 恢復空值狀態
    allLi.forEach(el => {
      el.classList.remove('active');
    });
    d4.s.activeLi = undefined;
    // 判斷單選或複選
    switch (d4.s.selectType) {
      case 'single':
        d4.s.value = {
          index: -1,
          id: undefined,
          el: undefined,
        };
        break;
      case 'multiple':
        d4.s.value = [];
        break;
    }

    d4.s.selectDisplayEl.textContent = placeholder.trim();
    d4.setAttribute('d4-value', '');
  } else {
    if (d4.classList.contains('disabled')) {
      d4.s.selectDisplayEl.textContent = placeholder.trim();
      return;
    }
    // 判斷單選或複選
    switch (d4.s.selectType) {
      case 'single':
        const option = allLi[valueIndex].textContent.trim();
        d4.s.allLi.forEach(el => {
          el.classList.remove('active');
        });
        allLi[valueIndex].classList.add('active');
        d4.s.selectDisplayEl.textContent = option;
        d4.s.activeLi = allLi[valueIndex];
        d4.s.value = {
          index: valueIndex,
          id: allLi[valueIndex].getAttribute('data-option'),
          el: allLi[valueIndex],
        };
        break;
      case 'multiple':
        if (Array.isArray(valueIndex)) {
          d4.s.allLi.forEach(li => {
            if (valueIndex.indexOf([...d4.s.allLi].indexOf(li)) >= 0) {
              li.classList.add('active');
            } else {
              li.classList.remove('active');
            }
          });
        }
        const activeLi = d4.querySelectorAll('.dropdown-list li.active');
        if (activeLi.length <= 0) {
          d4.s.selectDisplayEl.textContent = placeholder.trim();
        } else {
          d4.s.selectDisplayEl.textContent = '';
          activeLi.forEach(li => {
            const id = li.getAttribute('data-option');
            const option = li.textContent.trim();
            const tagEl = `<div class="option-btn" data-option="${id}"><div class="text">${option}</div><div class="remove-icon"></div></div>`;
            d4.s.selectDisplayEl.insertAdjacentHTML(insert.append, tagEl);
          });
        }
        d4.s.activeLi = activeLi;
        d4.s.value = [...activeLi].map(option => {
          const obj = {
            index: [...allLi].indexOf(option),
            id: option.getAttribute('data-option'),
            el: option,
          };
          return obj;
        });
        const valueArray = [...activeLi].map(option => option.getAttribute('data-option'));
        d4.setAttribute('d4-value', valueArray.join());
        break;
    }
  }
};

/**
 * 載入台灣縣市資料
 * @param {*} d4 d4 Element
 */
const loadCityData = d4 => {
  const lang = d4.s.cityLang;
  // 塞入縣市資料
  if (d4.classList.contains('city')) {
    d4.s.dropdownEl.querySelector('.dropdown-list').innerHTML = '';
    Object.keys(cityData[lang]).forEach(city => {
      const li = document.createElement('li');
      li.textContent = city;
      li.setAttribute('data-option', city);
      d4.s.dropdownEl.querySelector('.dropdown-list').append(li);
    });
    d4.s.allLi = d4.querySelectorAll('.dropdown-list li');
  }
};

/**
 * 載入對應地區資料
 */
const loadDistData = (d4, city) => {
  const lang = d4.s.cityLang;
  // 塞入地區資料
  cityData[lang][city].forEach((dist, index) => {
    const li = document.createElement('li');
    li.textContent = dist[0];
    li.setAttribute('data-option', dist[0]);
    d4.querySelector('.dropdown-list').append(li);
  });
};

/**
 * 關閉全部下拉選單
 */
const closeAllDropdown = () => {
  const allDropdown = getAllElements('dropdown-el[d4-status="open"]');
  allDropdown.forEach(el => {
    el.close();
  });
};

/**
 * 設定卷軸樣式
 */
const settingScrollbarStyle = () => {
  const { SETTINGS } = fesdDB.dropdown4;
  const setProperty = (element, obj) => {
    Object.keys(obj).forEach(key => {
      element.style.setProperty(`--${key}`, obj[key]);
    });
  };
  setProperty(document.documentElement, SETTINGS.scrollbar);
};

settingScrollbarStyle();
// 點擊空白處關閉下拉選單
document.addEventListener('click', function () {
  closeAllDropdown();
});

// 創建 Custom Element
class Dropdown4 extends HTMLElement {
  constructor() {
    super();
    this.initialize = false;
  }
  static get observedAttributes() {
    return ['d4-status', 'd4-placeholder', 'd4-value'];
  }
  attributeChangedCallback(attr, oldVal, newVal) {
    const d4 = this;
    switch (attr) {
      case 'd4-status':
        if (oldVal === null || oldVal === newVal) return;
        if (newVal === 'open' || newVal === 'close') {
          d4.emit(newVal);
        }
        break;
      case 'd4-value':
        if (oldVal === null || oldVal === newVal) return;
        if (newVal !== '') {
          const selectType = d4.hasAttribute('multiple') ? 'multiple' : 'single';
          switch (selectType) {
            case 'single':
              const li = d4.querySelector(`.dropdown-list li[data-option="${newVal}"]`);
              if (isElementExist(li)) {
                setSelectDisplay(d4, [...d4.s.allLi].indexOf(li));
              } else {
                setSelectDisplay(d4, -1);
              }
              break;
            case 'multiple':
              const valueLiArray = [];
              d4.querySelector(`.dropdown-list li`).classList.remove('active');
              newVal.split(',').forEach(option => {
                const li = d4.querySelector(`.dropdown-list li[data-option="${option}"]`);
                if (isElementExist(li)) {
                  li.classList.add('active');
                  valueLiArray.push(li);
                }
              });
              const valueIndexArray = valueLiArray.map(li => [...d4.s.allLi].indexOf(li));
              setSelectDisplay(d4, valueIndexArray);
              break;
          }
        } else {
          setSelectDisplay(d4, -1);
        }
        d4.emit('change');
        break;
      case 'd4-placeholder':
        if (oldVal === null) return;
        if (oldVal !== newVal && d4.s.value.index < 0) {
          setSelectDisplay(d4, d4.s.value.index);
        }
        break;
    }
  }
  connectedCallback() {
    const d4 = this;
    if (d4.initialize || d4.classList.contains('d4-initialize')) return;
    d4.initialize = true;
    this.#create();
  }
  #create() {
    this.s = {};
    this.__events__ = {};

    if (!this.hasAttribute('d4-status')) {
      this.setAttribute('d4-status', 'close');
    }
    if (!this.hasAttribute('d4-value')) {
      this.setAttribute('d4-value', '');
    }
    this.#mount();
  }
  #mount() {
    this.s.childDom = this.childNodes;
    this.s.template = createTemplate(this);
    this.innerHTML = '';
    this.append(this.s.template);
    this.#init();
  }
  #init() {
    const d4 = this;
    d4.s.allLi = d4.querySelectorAll('.dropdown-list li');
    d4.s.selectDisplayEl = d4.querySelector('.select-display');
    d4.s.dropdownEl = d4.querySelector('.dropdown');
    d4.s.selectType = d4.hasAttribute('multiple') ? 'multiple' : 'single';
    d4.s.cityLang = d4.hasAttribute('city-lang') ? d4.getAttribute('city-lang') : 'zh-tw';
    loadCityData(d4);
    d4.s.subDropdownTotalH = 0;
    const value = d4.getAttribute('d4-value');
    switch (d4.s.selectType) {
      case 'single':
        let valueLi;
        // 如果是地區下拉選單
        if (d4.classList.contains('dist')) {
          const cityDropdown = document.querySelector(`dropdown-el[dist-select="${d4.id}"]`);
          //判斷縣市下拉選單是否有值
          const cityDropdownHasVal = cityDropdown ? cityDropdown.getAttribute('d4-value') !== '' : false;
          if (cityDropdown && cityDropdownHasVal) {
            const city = cityDropdown.getAttribute('d4-value');
            loadDistData(d4, city);
            d4.s.allLi = d4.querySelectorAll('.dropdown-list li');
            d4.classList.remove('disabled');
            if (d4.getAttribute('d4-value') !== '') {
              const dist = value.split(',')[1];
              valueLi = d4.querySelector(`.dropdown-list li[data-option="${dist}"]`);
              d4.setAttribute('d4-value', dist);
            }
          }
        } else {
          valueLi = d4.querySelector(`.dropdown-list li[data-option="${value}"]`);
        }
        if (isElementExist(valueLi)) {
          valueLi.classList.add('active');
          d4.s.activeLi = valueLi;
          d4.s.value = {
            index: [...d4.s.allLi].indexOf(valueLi),
            id: value,
            el: valueLi,
          };
        } else {
          d4.s.activeLi = undefined;
          d4.s.value = {
            index: -1,
            id: undefined,
            el: undefined,
          };
        }
        break;
      case 'multiple':
        const valueLiArray = [];
        value.split(',').forEach(option => {
          const li = d4.querySelector(`.dropdown-list li[data-option="${option}"]`);
          if (isElementExist(li)) {
            valueLiArray.push(li);
          }
        });
        if (valueLiArray.length > 0) {
          d4.s.value = {
            index: valueLiArray.map(li => [...d4.s.allLi].indexOf(li)),
            id: valueLiArray.map(li => li.getAttribute('data-option')),
            el: valueLiArray.map(li => li),
          };
        } else {
          d4.s.value = {
            index: [],
            id: undefined,
            el: undefined,
          };
        }
        break;
    }
    setSelectDisplay(d4, d4.s.value.index);
    d4.#event();
    d4.classList.add('d4-initialize');
  }
  #event() {
    const d4 = this;
    // 下拉選單開關
    d4.__events__.dropdownToggle = () => {
      d4.addEventListener('click', function (e) {
        e.stopPropagation();
        const status = d4.getAttribute('d4-status');
        switch (status) {
          case 'open':
            d4.close();
            break;
          case 'close':
            d4.open();
            break;
        }
      });
    };

    // 下拉選單完成過渡
    d4.__events__.transitionend = () => {
      function toggleTransitionend(event) {
        if (event.target === this) {
          const dropdownStatus = d4.getAttribute('d4-status');
          const hasFilter = d4.classList.contains('filter');
          switch (dropdownStatus) {
            case 'open':
              const scroller = d4.querySelector('.dropdown-scroller');
              // 捲動到 active 的選項
              const scrollToActive = () => {
                if (d4.__scroller__) {
                  const { viewport } = d4.__scroller__.elements();
                  if (isElementExist(d4.s.activeLi)) {
                    const halfActiveLiHeight = d4.s.activeLi.clientHeight / 2;
                    const offsetTop = d4.s.activeLi.offsetTop;
                    const scroll = offsetTop - scroller.clientHeight / 2 + halfActiveLiHeight > 0 ? offsetTop - scroller.clientHeight / 2 + halfActiveLiHeight : 0;
                    viewport.scrollTo({
                      top: scroll,
                      behavior: 'smooth',
                    });
                  } else {
                    viewport.scrollTo({
                      top: 0,
                      behavior: 'smooth',
                    });
                  }
                }
              };
              if (hasFilter) {
                d4.querySelector('.filter-bar input').disabled = false;
                d4.querySelector('.filter-bar input').focus();
              }
              if (d4.s.selectType === 'single') {
                scrollToActive();
              }
              break;
            case 'close':
              d4.__scroller__.update(true);
              d4.s.dropdownEl.removeAttribute('style');
              if (hasFilter) d4.querySelector('.filter-bar input').disabled = true;
              break;
          }
        }
      }
      d4.s.dropdownEl.addEventListener('transitionend', toggleTransitionend);
    };

    // 綁定卷軸
    d4.__events__.bindScrollbar = (d4 = this) => {
      const scroller = d4.querySelector('.dropdown-scroller');
      // 綁定卷軸套件
      d4.__scroller__ = OverlayScrollbars(scroller, {
        overflowBehavior: {
          x: 'hidden',
        },
      });
    };

    // 顯示選的選項
    d4.__events__.selectOption = (d4 = this) => {
      let computedHeight, totalHeight;
      const scroller = d4.querySelector('.dropdown-scroller');
      const citySelect = d4.classList.contains('city');
      d4.s.allLi = d4.querySelectorAll('.dropdown-list li');
      d4.s.allLi.forEach(option => {
        option.addEventListener('click', function (e) {
          const clickOption = this;
          const clickIndex = [...d4.s.allLi].indexOf(clickOption);
          // 子下拉選單打開前的總高度
          const defaultHeight = parseInt(d4.getAttribute('d4-default-height'));
          // 有子層選單
          if (clickOption.classList.contains('has-sublayer')) {
            e.stopPropagation();
            const subDropdown = clickOption.querySelector('.sub-dropdown');
            const subDropdownList = subDropdown.querySelector('.sub-dropdown-list');
            const subDropdownListMargin = parseInt(getComputedStyle(subDropdownList).marginTop) + parseInt(getComputedStyle(subDropdownList).marginBottom);
            subDropdown.style.cssText = `--height: ${subDropdownList.offsetHeight + subDropdownListMargin}px`;
            // 子下拉選單高度
            const subDropdownH = parseInt(subDropdown.style.cssText.replace('--height:', '').trim());
            // 最大高度
            const maxHeight = parseInt(getComputedStyle(scroller).maxHeight);
            const recalculate = () => {
              // 重新計算整個下拉選單的高度
              computedHeight = defaultHeight + d4.s.subDropdownTotalH;
              totalHeight = computedHeight > maxHeight ? maxHeight : computedHeight;
              d4.style.cssText = `--maxHeight: ${totalHeight}px;`;
              d4.s.dropdownEl.style.height = `${totalHeight}px`;
            };
            if (!clickOption.classList.contains('open')) {
              // 子層下拉選單打開
              clickOption.classList.add('open');
              d4.s.subDropdownTotalH += subDropdownH;
              recalculate();
            } else {
              // 子層下拉選單關閉
              clickOption.classList.remove('open');
              d4.s.subDropdownTotalH -= subDropdownH;
              recalculate();
            }
            const collapse = () => {
              d4.__scroller__.update(true);
              subDropdown.removeEventListener('transitionend', collapse);
            };
            subDropdown.addEventListener('transitionend', collapse);
          }
          // 沒有子層選單
          else {
            detectSync(d4);
            // 判斷單選或複選
            switch (d4.s.selectType) {
              // 單選
              case 'single':
                d4.setAttribute('d4-value', clickOption.getAttribute('data-option'));
                // 選擇縣市
                if (citySelect) {
                  const lang = d4.s.cityLang;
                  const city = option.textContent.trim();
                  const distSelectEl = document.getElementById(d4.getAttribute('dist-select'));
                  if (distSelectEl) {
                    distSelectEl.querySelector('.dropdown-list').textContent = '';
                    setSelectDisplay(distSelectEl, -1);
                    cityData[lang][city].forEach((dist, index) => {
                      const li = document.createElement('li');
                      li.textContent = dist[0];
                      li.setAttribute('data-option', dist[0]);
                      distSelectEl.querySelector('.dropdown-list').append(li);
                    });
                    d4.__events__.selectOption(distSelectEl);
                  }
                }
                if (clickOption.parentNode.closest('li')?.classList.contains('has-sublayer')) {
                  e.stopPropagation();
                  d4.close();
                }
                break;
              // 複選
              case 'multiple':
                e.stopPropagation();
                clickOption.classList.toggle('active');
                setSelectDisplay(d4, clickIndex);
                break;
            }
          }
        });
      });
    };

    // 篩選功能
    d4.__events__.filterHandler = () => {
      if (d4.classList.contains('filter')) {
        d4.querySelector('.filter-bar').addEventListener('click', function (e) {
          e.stopPropagation();
        });
        d4.querySelector('.filter-input').addEventListener('input', function () {
          const val = this.value.toUpperCase();
          d4.s.allLi.forEach(el => {
            if (!el.textContent.toUpperCase().includes(val)) el.style.display = 'none';
            else el.removeAttribute('style');
          });
        });
      }
    };

    // 複選標籤移除
    d4.__events__.removeTag = () => {
      if (d4.s.selectType === 'single') return;
      d4.s.selectDisplayEl.addEventListener('click', function (e) {
        if (e.target.classList.contains('select-display')) return;
        const optionBtn = e.target.classList.contains('option-btn') ? e.target : e.target.parentElement;
        if (optionBtn.contains(e.target)) {
          e.stopPropagation();
          if (e.target.classList.contains('remove-icon')) {
            const tag = e.target.parentElement;
            const id = tag.getAttribute('data-option');
            d4.s.dropdownEl.querySelector(`.dropdown-list li[data-option="${id}"]`).classList.remove('active');
            d4.s.activeLi = d4.querySelectorAll('.dropdown-list li.active');
            e.target.parentElement.remove();
            const activeLiArray = [...d4.s.activeLi];
            d4.s.value = activeLiArray.map(option => {
              const obj = {
                index: [...d4.s.allLi].indexOf(option),
                id: option.getAttribute('data-option'),
                el: option,
              };
              return obj;
            });
            d4.s.activeLi = activeLiArray;
            const valueArray = activeLiArray.map(option => option.getAttribute('data-option'));
            d4.setAttribute('d4-value', valueArray.join());
            if (activeLiArray.length <= 0) {
              d4.s.selectDisplayEl.textContent = d4.getAttribute('d4-placeholder').trim();
            }
          }
        }
      });
    };
    d4.__events__.dropdownToggle();
    d4.__events__.bindScrollbar();
    d4.__events__.selectOption();
    d4.__events__.filterHandler();
    d4.__events__.removeTag();
    d4.__events__.transitionend();
  }
  open() {
    const d4 = this;
    const dropdownHeight = d4.querySelector('.dropdown-scroller').clientHeight;
    const hasFilter = d4.classList.contains('filter');
    const totalHeight = () => {
      if (hasFilter) {
        return d4.querySelector('.filter-bar').clientHeight + dropdownHeight;
      }
      return dropdownHeight;
    };
    closeAllDropdown();
    d4.setAttribute('d4-status', 'open');
    d4.setAttribute('d4-default-height', totalHeight());
    d4.style.cssText = `--maxHeight: ${totalHeight()}px;`;
    d4.s.dropdownEl.style.cssText = `
      height: ${totalHeight()}px;
      z-index: 2;
    `;
    return this;
  }
  close() {
    const d4 = this;
    const allSublayer = d4.s.dropdownEl.querySelectorAll('.has-sublayer');
    const defaultHeight = parseInt(d4.getAttribute('d4-default-height'));
    d4.setAttribute('d4-status', 'close');
    d4.s.dropdownEl.style.height = '0px';
    allSublayer.forEach(item => {
      item.classList.remove('open');
    });
    d4.style.cssText = `--maxHeight: ${defaultHeight}px;`;
    d4.s.subDropdownTotalH = 0;
    return this;
  }
  scrollTo(scroll) {
    const d4 = this;
    const { viewport } = d4.__scroller__.elements();
    viewport.scrollTo({
      top: scroll,
      behavior: 'smooth',
    });
  }
  update() {
    this.__events__.selectOption();
  }
}

Object.assign(Dropdown4.prototype, SHARED);

// define custom element
if (!customElements.get('dropdown-el')) {
  customElements.define('dropdown-el', Dropdown4);
}

export default Dropdown4;
