function createRipple(e, r4) {
  const button = r4;
  const { color, opacity, duration } = r4.s.options;

  // 可以更改點擊時生成效果樣式的結構
  let ripples = document.createElement('span');

  //偵測滑鼠點擊位置
  let x = e.clientX - button.getBoundingClientRect().left;
  let y = e.clientY - button.getBoundingClientRect().top;

  ripples.style.cssText = `background: ${color};left: ${x}px;top: ${y}px;opacity: ${opacity};animation-duration: ${duration}ms`;
  ripples.classList.add('circle');

  // 生成
  button.appendChild(ripples);

  // 生成後消失
  setTimeout(function () {
    ripples.remove();
  }, `${duration}`);
}

function removeStyle() {
  document.querySelectorAll('ripple-btn').forEach(button => {
    const ball = button.querySelector('.hover-ball');
    button.style.setProperty('--r', '');
    button.classList.remove('entered');
  });
}

document.addEventListener('click', removeStyle);

class Ripple4 extends HTMLElement {
  constructor() {
    super();
    this.initialize = false;
  }

  connectedCallback() {
    const r4 = this;
    if (r4.initialize || r4.classList.contains('r4-initialize')) return;
    r4.initialize = true;
    this.#create();
  }

  #create() {
    const { SETTINGS } = fesdDB.ripple4;
    this.s = {};
    this.s.eventEffect = this.getAttribute('r4-hover') === 'true' ? true : SETTINGS.hover;
    function attrToBoolean(str) {
      let booleanVal = Boolean(str);
      booleanVal = str === 'true';
      return booleanVal;
    }
    const options = {
      color: this.getAttribute('r4-color') || SETTINGS.color,
      opacity: this.getAttribute('r4-opacity') || SETTINGS.opacity,
      duration: Number(this.getAttribute('r4-duration')) || SETTINGS.duration,
      speed: Number(this.getAttribute('r4-speed')) || SETTINGS.speed,
      hover: this.getAttribute('r4-hover') ? attrToBoolean(this.getAttribute('r4-hover')) : SETTINGS.hover,
      click: this.getAttribute('r4-hover-click') ? attrToBoolean(this.getAttribute('r4-hover-click')) : SETTINGS.click,
    };

    this.s.options = options;
    if (this.s.eventEffect) this.classList.add('hover-btn');

    this.#init();
  }

  #init() {
    this.#ball();
    this.#event();
    this.classList.add('r4-initialize');
  }

  #ball() {
    const button = this;
    const ball = document.createElement('i');
    ball.classList.add('hover-ball');
    ball.style.transitionDuration = `${button.s.options.speed}ms`;
    button.appendChild(ball);
  }

  #event() {
    const ball = this.querySelector('i.hover-ball');
    const button = this;
    // ripple點擊事件
    button.addEventListener('click', function (e) {
      e.stopPropagation();
      if (button.s.options.click) {
        createRipple(e, button);
      }
    });

    // 判斷操作事件使用 mouse 或是 touch
    let operateStart = 'ontouchstart' in document.documentElement ? 'touchstart' : 'mouseenter';
    let operateEnd = 'ontouchend' in document.documentElement ? 'touchend' : 'mouseleave';
    // hover
    button.addEventListener(operateStart, function (e) {
      if (operateStart === 'touchstart') {
        removeStyle();
      }
      if (button.s.options.hover) {
        const posX = operateStart === 'mouseenter' ? Math.round(e.clientX - button.getBoundingClientRect().left) : Math.round(e.changedTouches[0].clientX - button.getBoundingClientRect().x);
        const posY = operateStart === 'mouseenter' ? Math.round(e.clientY - button.getBoundingClientRect().top) : Math.round(e.changedTouches[0].clientY - button.getBoundingClientRect().y);
        const { offsetWidth, offsetHeight } = button;
        // 最大可覆蓋之半徑
        const rippleR = Math.ceil(Math.sqrt(offsetWidth ** 2 + offsetHeight ** 2) * 2);
        button.style.setProperty('--r', rippleR);
        ball.style.left = posX + 'px';
        ball.style.top = posY + 'px';
        button.classList.add('entered');
      }
    });
    button.addEventListener(operateEnd, function (e) {
      if (operateEnd === 'touchend') return;
      if (button.s.options.hover) {
        const posX = operateEnd === 'mouseleave' ? Math.round(e.clientX - button.getBoundingClientRect().left) : Math.round(e.changedTouches[0].clientX - button.getBoundingClientRect().x);
        const posY = operateEnd === 'mouseleave' ? Math.round(e.clientY - button.getBoundingClientRect().top) : Math.round(e.changedTouches[0].clientY - button.getBoundingClientRect().y);
        button.style.setProperty('--r', '');
        ball.style.left = posX + 'px';
        ball.style.top = posY + 'px';
        button.classList.remove('entered');
      }
    });
  }
  update() {
    this.classList.remove('r4-initialize');
    this.querySelector('i.hover-ball').remove();
    this.#create();
  }
}

if (!customElements.get('ripple-btn')) {
  customElements.define('ripple-btn', Ripple4);
}

export default Ripple4;
